// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "../stylesheets/application.scss";
import "bootstrap";

// https://gist.github.com/bazzel/ecdff4718962e57c2d5569cf01d332fe
import I18n from 'i18n-js'

require('admin-lte/dist/js/adminlte');
require('packs/components');

const images = require.context('../images', true)
window.bootstrap = require('bootstrap');
window.moment = require('moment');
window.Routes = require('js-routes/index.js.erb');

Rails.start()
ActiveStorage.start()


$(function(){
  $('[data-toggle="tooltip"]').tooltip({ html: true })
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});
